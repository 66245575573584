import React from "react";
import PhoneInput from "react-phone-input-2";
import { RiContactsLine } from "react-icons/ri";
import '../Register.css'

const Step1 = ({ shopName, setShopName, phone, setPhone, errors, onNext, isLoading, otpVerified }) => {
  return (
    <div>
    <div className="input-group">
    <RiContactsLine className="input-icon" />
    <input
    type="text"
    value={shopName}
    onChange={(e) => setShopName(e.target.value)}
    placeholder="Enter Shop Name"
    className="input-field"
    autoComplete="name"
    />
    {errors.shopName && <p className="error">{errors.shopName}</p>}
    </div>
    <div className="input-group">
    <PhoneInput
    country={"in"}
    enableSearch={true}
    value={phone}
    onChange={(value) => setPhone(value)}
    placeholder= "Enter your phone number"
    inputProps={{
      className: "input-field",
    }}
    countryCodeEditable={false}
    buttonClass="phone-input-btn"
    autoComplete= "tel"
    />
    {errors.phone && <p className="error">{errors.phone}</p>}

    {otpVerified !== null && (
      <div className="verification-message">
      <p>{otpVerified ? "Mobile Verified!" : ""}</p>
      </div>
    )}

    </div>
    <div className="next-btn-div">
    <button type="button" onClick={onNext} className="right-align" disabled={isLoading}>
    {isLoading ? (
      <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
    ) : (
      <>
      Next <span className="m-2 fs-5">&rarr;</span>
      </>
    )}
    </button>
    </div>
    </div>
  );
};

export default Step1;

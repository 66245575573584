import React, { useState, useRef } from "react";
import "react-phone-input-2/lib/bootstrap.css";
import ZadpiLogo from "../../assets/ZadpiLogo.png";
import "./Register.css";
import axios from "axios";
import Step1 from "./RegisterSteps/Step1";
import Step2 from "./RegisterSteps/Step2";
import Step3 from "./RegisterSteps/Step3";
import Step4 from "./RegisterSteps/Step4";
import Step5 from "./RegisterSteps/Step5";
import { Api } from "../../Api";

const Register = () => {
  const [step, setStep] = useState(1);
  // const progressPercentage = (step / 5) * 100;

  const [shopName, setShopName] = useState("");
  const [phone, setPhone] = useState("");
  const phoneRef = useRef(null);
  const [otp, setOtp] = useState([]);
  const otpRefs = useRef([]);
  const [otpSent, setOtpSent] = useState(false);
  const [otpVerified, setOtpVerified] = useState(null);
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const [isConfirmPasswordVisible, setIsConfirmPasswordVisible] = useState(false);
  const [email, setEmail] = useState("");
  const [address, setAddress] = useState("");
  const [area, setArea] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [pincode, setPincode] = useState("");
  const [frontPreview, setFrontPreview] = useState(null);
  const [backPreview, setBackPreview] = useState(null);
  const [frontImage, setFront] = useState(null);
  const [backImage, setBack] = useState(null)
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [errors, setErrors] = useState({});
  const [isStep1Completed,setIsStep1Complete] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [companyId, setCompanyId] = useState("");
  
  // useEffect(() => {
    //     console.log(otp);
  // }, [step]);
  
  const validateStep = () => {
    const newErrors = {};
    
    if (step === 1) {
      if (!shopName.trim()) newErrors.shopName = "Shop name is required.";
      if (!phone || phone.length < 12) newErrors.phone = "A valid phone number is required.";
    }
    
    if (step === 2) {
      if (otp.join("").length !== 4) newErrors.otp = "OTP must be 4 digits.";
      if (!verifyOtp()) newErrors.otp = "OTP is invalid. Please try again.";
    }
    
    if (step === 3) {
      if (password.length < 6) {
        newErrors.password = "Password must be at least 6 characters.";
      }
      if (password.length >= 6 && password !== confirmPassword) newErrors.confirmPassword = "Passwords do not match.";
    }
    
    if (step === 4) {
      if (!email) {
        newErrors.email = "Email is required.";
      } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)) {
        newErrors.email = "Invalid Email Format.";
      }
      if (!address.trim()) newErrors.address = "Address is required.";
      if (!area.trim()) newErrors.area = "Area is required.";
      if (!city.trim()) newErrors.city = "City is required.";
      if (!state.trim()) newErrors.state = "State is required.";
      const pincodeRegex = /^[1-9][0-9]{5}$/;
      if (!pincodeRegex.test(pincode)) newErrors.pincode = "Pincode must be 6 digits"
    }
    
    // if (step === 5) {
    //   if (!frontPreview && !backPreview) newErrors.filePreview = "At least one image (front or back) is required.";
    // }
    
    setErrors(newErrors);
    
    return Object.keys(newErrors).length === 0;
  };
  
  const handleNext = async () => {
    if (validateStep()) {
      if (step === 1) {
        setIsLoading(true);
        try {
          if (!isStep1Completed) {
            // First time submission
            const response = await axios.post(Api.register, {
              CompName: shopName,
              CompMobileNo: phone,
            });
            
            if (response.data.status === 1) {
              const cId = response.data.data?.id;
              setCompanyId(cId);
              
              phoneRef.current = phone;
              // console.log('Ref 1st step: ',phoneRef.current);
              setIsStep1Complete(true);
              setOtpSent(true);
              // Check if MobileStatus is in the response data
              if (response.data.data?.MobileStatus === 'verified') {
                // If MobileStatus is 'verified', move to Step 3
                setOtpVerified(true)
                setStep(3);
              } else {
                // If MobileStatus is not 'verified', move to Step 2
                setStep(2);
              }
            } else {
              setErrors((prevErrors) => ({
                ...prevErrors,
                phone: response.data.message || "Failed to register company.",
              }));
            }
          } else {
            // Subsequent request (using stored company ID)
            // console.log('ID at Subsequent: ',companyId)
            const response = await axios.post(Api.register, {
              id: companyId, // Include the stored ID in the request
              CompName: shopName,
              CompMobileNo: phone,
            });
            // console.log('Ref:',phoneRef.current, 'phone:',phone);
            
            if (response.data.status === 1) {
              // console.log('SubSequent visit:');
              
              if (phoneRef.current !== phone || response.data.data?.MobileStatus !== 'verified') { 
                // Phone number changed
                phoneRef.current = phone; // Update phone reference
                setOtpSent(true); // Set OTP Sent
                setOtp(Array(4).fill(''));
                setOtpVerified(false)
                setStep(2); // Proceed to Step 2 and clear OTP fields
              } else {
                setStep(3)
              }
            } else {
              // console.log('inner: ',response.data.message);
              setErrors((prevErrors) => ({
                ...prevErrors,
                phone: response.data.message,
              }));
            }
          }
        } catch (error) {
          // console.log('outer:',error.response?.data.message);
          
          setOtpVerified(false)
          setErrors((prevErrors) => ({
            ...prevErrors,
            phone: error.message ||
            "An error occurred while registering the company.",
          }));
        } finally {
          setIsLoading(false);
        }
      }
      else if(step === 2){
        const otpVerifiedSuccessfully = await verifyOtp();
        if (otpVerifiedSuccessfully) {
          setStep((prevStep) => prevStep + 1);
        }
      } else if (step === 3) {
        const passwordUpdated = await updatePassword();
        if (passwordUpdated) {
          setStep((prevStep) => prevStep + 1);
        }
      } else if (step === 4) {
        const companyUpdated = await updateCompanyDetails();
        if (companyUpdated) {
          setStep((prevStep) => prevStep + 1);
        }
      } else {
        setStep((prevStep) => prevStep + 1);
      }
    }
  };
  
  const handleSubmit = async (e) => {
    e.preventDefault();
    // console.log('Submitted');
    if (validateStep()) {
      if(step === 5){
        try {
          setIsLoading(true)
          const formData = new FormData();

          formData.append("CompName", shopName);
          formData.append("CompMobileNo", phone);
          if (frontImage) {
            formData.append("VisitingCardFront", frontImage); // Make sure `frontImage` is a File object
          }
          if (backImage) {
            formData.append("VisitingCardBack", backImage); // Make sure `backImage` is a File object
          }
          
          // console.log([...formData]);
          
          const response = await axios.post(Api.editCompany, formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          });
          
          // console.log("Try block after API");
          if (response.data.status === 1) {
            setIsSubmitted(true); // Mark as submitted if successful
            // console.log(isSubmitted);
          } else {
            setErrors((prevErrors) => ({
              ...prevErrors,
              filePreview: response.data.message || "Failed to upload files.",
            }));
          }
        } catch (error) {
          // console.log("Catch block");
          // console.error("Error:", error);
          setErrors((prevErrors) => ({
            ...prevErrors,
            filePreview: error.response?.data?.message || "An error occurred while uploading files.",
          }));
        } finally {
          setIsLoading(false);
        }        
      }
    };
  }
  
  const handleFileChange = (e, type) => {
    const file = e.target.files[0];
    if (file) {
      const previewURL = URL.createObjectURL(file);
      if (type === "front") {
        setFront(file); // Store the file for API submission
        setFrontPreview(previewURL); // Store the preview URL
      } else {
        setBack(file); // Store the file for API submission
        setBackPreview(previewURL); // Store the preview URL
      }
    }
  };
  
  const handleOtpChange = (e, index) => {
    const value = e.target.value;
    setOtp((prevOtp) => {
      const updatedOtp = [...prevOtp];
      updatedOtp[index] = value;
      return updatedOtp;
    });
    
    if (value && index < 3) {
      otpRefs.current[index + 1]?.focus();
    }
  };
  
  const handleKeyDown = (e, index) => {
    if (e.key === 'Backspace' && !otp[index] && index > 0) {
      otpRefs.current[index - 1]?.focus();
    }
  };
  
  const verifyOtp = async () => {
    const otpString = otp.join('');
    setIsLoading(true);
    // console.log('VerifyOTP: ',phone , otpString);
    try {
      const response = await axios.post(Api.verifyOtp, {
        CompMobileNo: phone,
        CompOTP: otpString,
      });
      
      // console.log('Response : ',response);
      
      if (response.data.status === 1) {
        setOtpVerified(true);
        return true;
      } else {
        setOtpVerified(false);
        setErrors((prevErrors) => ({
          ...prevErrors,
          otp: "OTP is invalid. Please Try again.",
        }));
        return false;
      }
    } catch (error) {
      setOtpVerified(false);
      setErrors((prevErrors) => ({
        ...prevErrors,
        otp: "OTP is invalid. Please Try again.",
      }));
      return false;
    } finally {
      setIsLoading(false);
    }
  };
  
  const resendOtp = async () => {
    setOtpSent(false);
    setOtpSent(true);
    setOtp(Array(4).fill(''));
    setOtpVerified(false);
    setErrors((prevErrors) => ({ ...prevErrors, otp: "" }));
    
    try {
      // Make the API call to resend OTP
      const response = await axios.post(Api.sendOtp, {
        CompMobileNo: phone,
      });
      
      if (response.data.status === 1) {
        // OTP sent successfully
        // console.log("OTP sent successfully.");
      } else {
        // Handle failure in sending OTP
        // console.log(response.data.message);
        
        setErrors((prevErrors) => ({
          ...prevErrors,
          otp: response.data.message || "Failed to resend OTP. Please try again.",
        }));
      }
    } catch (error) {
      // Handle network or server error
      setErrors((prevErrors) => ({
        ...prevErrors,
        otp: error.response?.data?.message || "An error occurred while resending OTP.",
      }));
      // console.error("Error while sending OTP:", error);
    }
  };
  
  
  const updatePassword = async () => {
    setIsLoading(true);
    try {
      const response = await axios.post(Api.editCompany, {
        CompMobileNo: phone,
        CompPassword: password,
      });
      
      if (response.data.status === 1) {
        return true; // Password updated successfully
      } else {
      console.log(response.data.message);
        setErrors((prevErrors) => ({
          ...prevErrors,
          password: response.data.message || "Failed to update password.",
        }));
        return false;
      }
    } catch (error) {
      console.log(error.message);
      
      setErrors((prevErrors) => ({
        ...prevErrors,
        password: error.response?.data?.message || "An error occurred while updating the password.",
      }));
      return false;
    } finally {
      setIsLoading(false); // End loading
    }
  };
  
  const updateCompanyDetails = async () => {
    const updatedCompanyData = {
      CompMobileNo: phone,
      CompEmail: email,
      CompAddress: address,
      CompArea: area,
      CompCity: city,
      CompState: state,
      CompPinCode: pincode,
    };
    
    setIsLoading(true);
    try {
      const response = await axios.post(Api.editCompany, updatedCompanyData);
      
      if (response.data.status === 1) {
        return true;
      } else {
        setErrors((prevErrors) => ({
          ...prevErrors,
          email: response.data.message,
        }));
        return false;
      }
    } catch (error) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        email: error.response?.data?.message || "An error occurred while updating the company details.",
      }));
      return false;
    } finally {
      setIsLoading(false);
    }
  };
  
  return (
    <div className="form-container">
    {!isSubmitted ? (
      <form onSubmit={handleSubmit} className="input-form">
        {/* <div className="progress-bar-container">
        <div
          className="progress-bar"
          style={{ width: `${progressPercentage}%` }}>
        </div>
      </div> */}
      <a href="/" className="login-link">
      <span>&larr;</span> Login
      </a>
      
      <div className="logo">
      <img src={ZadpiLogo} alt="Logo" className="logo-img" />
      </div>
      
      <h1 className="form-heading">Register to <span style={{ fontFamily: "Batangas" }}>Zadpi</span></h1>
      
      {step === 1 && (
        <Step1
        shopName={shopName}
        setShopName={setShopName}
        phone={phone}
        setPhone={setPhone}
        errors={errors}
        onNext={handleNext}
        isLoading={isLoading}
        otpVerified={otpVerified}
        />
      )}
      
      {step === 2 && (
        <Step2
        otpSent={otpSent}
        phone={phone}
        otp={otp}
        errors={errors}
        otpVerified={otpVerified}
        resendOtp={resendOtp}
        handleOtpChange={handleOtpChange}
        handleKeyDown={handleKeyDown}
        setStep={setStep}
        onNext={handleNext}
        isLoading={isLoading}
        ref={otpRefs}
        />
      )}
      
      {step === 3 && (
        <Step3
        password={password}
        confirmPassword={confirmPassword}
        isPasswordVisible={isPasswordVisible}
        isConfirmPasswordVisible={isConfirmPasswordVisible}
        setPassword={setPassword}
        setConfirmPassword={setConfirmPassword}
        togglePasswordVisibility={() => setIsPasswordVisible(!isPasswordVisible)}
        toggleConfirmPasswordVisibility={() => setIsConfirmPasswordVisible(!isConfirmPasswordVisible)}
        errors={errors}
        handleNext={handleNext}
        setStep={setStep}
        isLoading={isLoading}
        />
      )}
      {step === 4 && (
        <Step4
        email={email}
        setEmail={setEmail}
        address={address}
        setAddress={setAddress}
        area={area}
        setArea={setArea}
        city={city}
        setCity={setCity}
        state={state}
        setState={setState}
        pincode={pincode}
        setPincode={setPincode}
        errors={errors}
        handleNext={handleNext}
        setStep={setStep}
        isLoading={isLoading}
        />
      )}
      
      {step === 5 && (
        <Step5
        frontPreview={frontPreview}
        backPreview={backPreview}
        handleFileChange={handleFileChange}
        errors={errors}
        setStep={setStep}
        isLoading={isLoading}
        />
      )}
      <div className="contact-details">
      <p><b>Contact:</b> Gurukrupa Enterprise | +91 9909340404</p>
      </div>
      </form>
    ) : (
      <div className="approval-container">
      <div className="approval-card">
      <h3>Pending for Approval</h3>
      <p>
      Your registration request has been submitted successfully and is pending
      approval from the administrator.
      </p>
      <div className="back-to-login-container">
      <a href="/" className="back-to-login-btn">
      <span className="back-arrow">←</span> Back to Login
      </a>
      </div>
      </div>
      </div>
    )}
    </div>
  );
};

export default Register;

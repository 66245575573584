import React from "react";
import { FaEye, FaEyeSlash, FaKey } from "react-icons/fa";

const PasswordField = ({ value, onChange, isVisible, toggleVisibility, placeholder }) => {
  return (
    <div className="form-group password-group">
      <label htmlFor="password" className="form-label">
        <FaKey /> {placeholder} (Minimum length 6)
      </label>
      <div className="input-group">
        <input
          type={isVisible ? "text" : "password"}
          id="password"
          className="input-field-iconless"
          value={value}
          onChange={onChange}
          placeholder={placeholder}
        />
        <span className="password-toggle" onClick={toggleVisibility}>
          {isVisible ? <FaEyeSlash /> : <FaEye />}
        </span>
      </div>
    </div>
  );
};

export default PasswordField;

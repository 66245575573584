import React, { useState, useEffect } from "react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";
import { FaLock, FaEye, FaEyeSlash } from "react-icons/fa";
import "./Login.css";
import ZadpiLogo from "../../assets/ZadpiLogo.png";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { Api } from "../../Api";

const Login = () => {
  const [phone, setPhone] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate(); 

  // ✅ Improved phone number validation
  const validatePhoneNumber = (phoneNumber) => {
    return /^\+?\d{10,15}$/.test(phoneNumber);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    setError("");
    setIsLoading(true);
  
    if (!validatePhoneNumber(`+${phone}`)) {
      setError("Please enter a valid phone number.");
      setIsLoading(false);
      return;
    }
  
    if (password.trim() === "") {
      setError("Password cannot be empty.");
      setIsLoading(false);
      return;
    }
  
    try {
      const response = await axios.post(Api.login, {
        CompMobileNo: phone,
        CompPassword: password,
      });
  
      const { status, message, data } = response.data;
  
      if (status === 1) {
        // Calculate expiration time (End of the day)
        const now = new Date();
        const expiry = new Date(now);
        expiry.setHours(23, 59, 59, 999); // Set expiration to today at 23:59:59
  
        // Store data in localStorage with expiry timestamp
        localStorage.setItem(
          "data",
          JSON.stringify({ ...data[0], expiry: expiry.getTime() })
        );
  
        navigate("/orders");
      } else {
        setError(message || "Invalid credentials. Please try again.");
      }
    } catch (error) {
      setError("An error occurred. Please try again later.");
    } finally {
      setIsLoading(false);
    }
  };  

  return (
    <div className="form-container">
      <form onSubmit={handleSubmit} className="input-form">
        <div className="logo">
          <img src={ZadpiLogo} alt="Logo" className="logo-img" />
        </div>
        <h1 className="form-heading">
          Login to <span style={{ fontFamily: "Batangas" }}>Zadpi</span>
        </h1>

        {/* 📞 Phone Number Input */}
        <div className="input-group">
          <PhoneInput
            country={"in"}
            enableSearch={true}
            value={phone}
            onChange={(value) => setPhone(value)}
            inputProps={{
              placeholder: "Enter your phone number",
              className: "input-field",
            }}
            countryCodeEditable={false}
            buttonClass="phone-input-btn"
            autoComplete="username"
          />
        </div>

        {/* 🔒 Password Input */}
        <div className="input-group">
          <FaLock className="input-icon" />
          <input
            type={showPassword ? "text" : "password"}
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            placeholder="Enter your password"
            className="input-field"
            autoComplete="current-password"
          />
          <div
            className="password-toggle"
            onClick={() => setShowPassword(!showPassword)}
          >
            {showPassword ? <FaEyeSlash /> : <FaEye />}
          </div>
        </div>

        {/* ⚠️ Error Message */}
        {error && <p className="error-message">{error}</p>}

        {/* 🔘 Login Button */}
        <button type="submit" className="login-btn" disabled={isLoading}>
          {isLoading ? (
            <span
              className="spinner-border spinner-border-sm"
              role="status"
              aria-hidden="true"
            ></span>
          ) : (
            "Login"
          )}
        </button>

        {/* 🔗 Links */}
        <div className="links">
          <a href="/forgetpassword" className="forgot-password">
            Forgot password?
          </a>
          <span className="no-account">
            Don't have an account? <a href="/register">Register</a>
          </span>
        </div>

        {/* 📞 Contact Info */}
        <div className="contact-details">
          <p>
            <b>Contact:</b> Gurukrupa Enterprise | +91 9909340404
          </p>
        </div>
      </form>
    </div>
  );
};

export default Login;

import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { FaTag } from "react-icons/fa";
import Navbar from "../../component/Navbar";
import "./Prices.css"; // For custom styles

const Prices = () => {
  const [priceData, setPriceData] = useState(null);
  const navigate = useNavigate();
  
  // ✅ Fetch data from localStorage
  const userData = JSON.parse(localStorage.getItem("data"));

  useEffect(() => {
      if (!userData) {
        navigate("/"); // Redirect to login page
      }
    }, [navigate, userData]);

  useEffect(() => {
    if (userData) {
      setPriceData({
        pdfPrice: userData.Price_SlipPdf,
        a4_4Price: userData.Price_Slip4,
        a4_6Price: userData.Price_Slip6,
        a4_9Price: userData.Price_Slip9,
      });
    }
  }, []);

  // Render the prices
  return (
    <div className="prices-page-container">
      <Navbar />

      <div className="prices-content">
        <header className="prices-header">
          <div className="price-type">
            <FaTag size={30} style={{ marginRight: "10px" }} />
            <h2>મતદાર સ્લીપ નો ભાવ </h2>
          </div>
        </header>

        <div className="price-cards-container">
          {priceData ? (
            <div className="price-cards">
              {/* PDF Price */}
              <div className="price-card">
                <h3>ફક્ત PDF</h3>
                <p className="price-value">₹{priceData.pdfPrice || "N/A"}</p>
                <p className="price-description">પ્રતિ સ્લીપ</p>
              </div>

              {/* A4 Ma 4 Price */}
              <div className="price-card">
                <h3>A4 માં 4 મલ્ટી કલર</h3>
                <p className="price-value">₹{priceData.a4_4Price || "N/A"}</p>
                <p className="price-description">પ્રતિ સ્લીપ</p>
              </div>

              {/* A4 Ma 6 Price */}
              <div className="price-card">
                <h3>A4 માં 6 મલ્ટી કલર</h3>
                <p className="price-value">₹{priceData.a4_6Price || "N/A"}</p>
                <p className="price-description">પ્રતિ સ્લીપ</p>
              </div>

              {/* A4 Ma 9 Price */}
              <div className="price-card">
                <h3>A4 માં 9 મલ્ટી કલર</h3>
                <p className="price-value">₹{priceData.a4_9Price || "N/A"}</p>
                <p className="price-description">પ્રતિ સ્લીપ</p>
              </div>
            </div>
          ) : (
            <div className="loading-message">
              <p>Loading prices...</p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Prices;

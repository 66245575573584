import React from "react";
import "../NewOrder.css";

const Step3OrderType = ({ choices, selectedChoice, onSelect }) => {
  return (
    <>
      <h3>Select Order Type</h3>
      <div className={`choices-container ${choices.length === 1 ? "grid-1" : choices.length === 2 ? "grid-2" : "grid-2x3"}`}>
        {choices.map((choice) => (
          <div
            key={choice.id}
            className={`choice-card ${selectedChoice && selectedChoice.id === choice.id ? 'selected' : ''}`}
            onClick={() => onSelect(choice.id)}
          >
            <div>
              <div>{choice.label}</div>
              <div className="gujrati">({choice.gujaratiLabel})</div>
            </div>
          </div>
        ))}
      </div>
    </>
  );
};

export default Step3OrderType;

import React, { useState, useEffect } from "react";
import { FaCreditCard } from "react-icons/fa";
import Navbar from "../../component/Navbar";
import "./Transaction.css";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { Api } from "../../Api";
import { format } from "date-fns";

const Transactions = () => {
  const navigate = useNavigate();
  const [transactions, setTransactions] = useState([]);
  const [message, setMessage] = useState("");

  // ✅ Get user data from localStorage
  const userData = JSON.parse(localStorage.getItem("data"));

  // ✅ Redirect to login if userData is null
  useEffect(() => {
    if (!userData) {
      navigate("/");
    }
  }, [navigate, userData]);

  const formatDate = (dateString) => {
    if (!dateString) return "N/A"; // Handle empty date
    return format(new Date(dateString), "dd/MM/yy hh:mm:ss a"); // Format to dd/mm/yy hh:mm:ss AM/PM
  };

  useEffect(() => {
    const fetchTransactions = async () => {
      if (userData) {
        try {
          const response = await axios.post(Api.getTransaction, { CompId: userData._id });
          
          if (response.data.status === 1) {
            setTransactions(response.data.data);
          } else {
            setMessage(response.data.message);
          }
        } catch (error) {
          console.error("Error fetching transactions:", error);
          setMessage(error.message);
        }
      }
    };

    fetchTransactions();
  }, []);

  // ✅ Calculate totalAmount (Credits)
  const totalAmount = parseFloat(
    transactions.reduce((sum, data) => sum + parseFloat(data.Amount || 0), 0)
  ).toFixed(2);

  return (
    <div className="main-container">
      <Navbar />

      <div className="content-container">
        <header className="transactions-header">
          <h1 className="shop-name">{userData?.CompName || "Shop Name"}</h1>
          <div className="credits-container">
            <p className={`credits`}>
              <FaCreditCard />
              <span className={`amount ${totalAmount < 0 ? "red-text" : "green-text"}`}>
                ₹{Math.abs(totalAmount)}
              </span>
              <span className={`status ${totalAmount < 0 ? "red-text" : "green-text"}`}>
                ({totalAmount < 0 ? "બાકી" : "જમા"})
              </span>
            </p>
          </div>
        </header>

        <div className="table-container">
          {transactions.length > 0 ? (
            <table className="transactions-table">
              <thead>
                <tr>
                  <th>Sr. No</th>
                  <th>Date</th>
                  <th>Amount</th>
                  <th>Order No</th>
                  <th>Description</th>
                </tr>
              </thead>
              <tbody>
                {transactions.map((txn, index) => (
                  <tr key={txn._id}>
                    <td>{index + 1}</td>
                    <td>{formatDate(txn.OrderDate)}</td>
                    <td className={parseFloat(txn.Amount) < 0 ? "red-text-td" : "green-text-td"}>
                      ₹{Math.abs(parseFloat(txn.Amount))}
                    </td>
                    <td>{txn.OrderNo || '-'}</td>
                    <td>{txn.Description || "-"}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          ) : (
            <div className="message-container">
              <p>{message}</p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Transactions;

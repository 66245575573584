import React, { useState, useEffect } from "react";
import { FaCreditCard, FaDownload, FaPlus } from "react-icons/fa";
import Navbar from "../../component/Navbar";
import "./Orders.css";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { Api } from "../../Api";
import { format } from "date-fns";
import NewOrder from "../NewOrder/NewOrder";

const Orders = () => {
  const navigate = useNavigate();
  const [orders, setOrders] = useState([]);
  const [transactions, setTransactions] = useState([]); // Store transactions
  const [message, setMessage] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isPaymentDialogOpen, setIsPaymentDialogOpen] = useState(false);
  const [compLimit, setCompLimit] = useState(null); // Store company limit here

  const formatDate = (dateString) => {
    if (!dateString) return "N/A"; // Handle empty date
    const date = new Date(dateString);
    return format(date, "dd/MM/yy hh:mm:ss a"); // Format to dd/mm/yy hh:mm:ss AM/PM
  };

  // ✅ Get user data from localStorage
  const userData = JSON.parse(localStorage.getItem("data"));

  // ✅ Redirect to login if userData is null
  useEffect(() => {
    if (!userData) {
      navigate("/");
    }
  }, [navigate, userData]);

  useEffect(() => {
    const fetchOrdersAndTransactions = async () => {
      if (userData) {
        try {
          // Fetch orders
          const ordersResponse = await axios.post(Api.getOrderList, { userId: userData._id });
          if (ordersResponse.data.status === 1) {
            setOrders(ordersResponse.data.data);
          } else {
            setMessage(ordersResponse.data.message || "No orders found.");
          }

          // Fetch transactions
          const transactionsResponse = await axios.post(Api.getTransaction, { CompId: userData._id });
          if (transactionsResponse.data.status === 1) {
            setTransactions(transactionsResponse.data.data);
          } else {
            setMessage(transactionsResponse.data.message || "No transactions found.");
          }

          const companyResponse = await axios.post(Api.getCompany, { id: userData._id });
          if (companyResponse.data.status === 1) {
            setCompLimit(companyResponse.data.data[0]?.CompLimit);
          } else {
            setMessage(companyResponse.data.message || "No company details found.");
          }
        } catch (error) {
          console.error("Error fetching orders or transactions:", error);
          setMessage(error.message);
        }
      }
    };

    fetchOrdersAndTransactions();
  }, []);

  const handleDownload = (pdfPath) => {
    const downloadUrl = `${Api.baseurl}${pdfPath}`; // Ensure this URL is publicly accessible
    window.open(downloadUrl, "_blank"); // Open the PDF in a new tab
  };
  
  // ✅ Calculate totalAmount (Credits) from transactions
  const totalAmount = parseFloat(
    transactions.reduce((sum, data) => sum + parseFloat(data.Amount || 0), 0)
  ).toFixed(2);

  // ✅ Handle opening modal or payment dialog
  const handleNewOrderClick = () => {
    if (compLimit && compLimit > Math.abs(totalAmount)) {
      setIsModalOpen(true);
    } else {
      setIsPaymentDialogOpen(true);
    }
  };
  
  const handleCloseModal = () => setIsModalOpen(false);

  return (
    <div className="main-container">
      <Navbar />

      <div className="content-container">
        <header className="orders-header">
          <h1 className="shop-name">{userData?.CompName || "Shop Name"}</h1>
          <div className="credits-container">
            <p className={`credits`}>
              <FaCreditCard />
              <span className={`amount ${totalAmount < 0 ? "red-text" : "green-text"}`}>
                ₹{Math.abs(totalAmount)}
              </span>
              <span className={`status ${totalAmount < 0 ? "red-text" : "green-text"}`}>
                ({totalAmount < 0 ? "બાકી" : "જમા"})
              </span>
            </p>
          </div>
        </header>
        <div className="new-order-container">
          <button className="new-order-btn" onClick={handleNewOrderClick}>
            <FaPlus className="icon" /> New Order
          </button>
        </div>

        {isModalOpen && <NewOrder handleClose={handleCloseModal} credits={totalAmount} />}

        <div className="table-container">
          {orders.length > 0 ? (
            <table className="orders-table">
              <thead>
                <tr>
                  <th>Order No</th>
                  <th>Download</th>
                  <th className="order-date-clm">Order Date</th>
                  <th>Order Status</th>
                  <th>Election Type</th>
                  <th>Name</th>
                  <th>Ward No</th>
                  <th>Slip Size</th>
                  <th>Slip Type</th>
                  <th>Mudrak</th>
                  <th>Prat</th>
                  <th>Slip Rate</th>
                  <th>Amount</th>
                  <th>Prakashak</th>
                  <th>Order Type</th>
                  <th>Image</th>
                </tr>
              </thead>
              <tbody>
                {orders.map((order) => (
                  <tr key={order._id}>
                    <td>{order.OrderNo}</td>
                    <td>
                      {order.OrderType === "PDF" && order.Pdf_Path ? (
                        <button
                          className="download-btn"
                          onClick={() => handleDownload(order.Pdf_Path)}
                        >
                          <FaDownload /> {/* React icon for download */}
                        </button>
                      ) : (
                        <span>-</span> // If no PDF path or OrderType is not PDF, show N/A
                      )}
                    </td>
                    <td>{formatDate(order.OrderDate)}</td>
                    <td>{order.OrderStatus}</td>
                    <td>{order.ElectionType}</td>
                    <td>{order.ElectionName}</td>
                    <td>{order.WardNo? order.WardNo : "-"}</td>
                    <td className="slip-size-clm">{order.SlipSize}</td>
                    <td className="slip-type-clm">{order.SlipType}</td>
                    <td>{order.Mudrak}</td>
                    <td>{order.Prat}</td>
                    <td>{order.SlipRate}</td>
                    <td>₹{order.Amount}</td>
                    <td>{order.Prashashak}</td>
                    <td>{order.OrderType}</td>
                    <td>
                      <img
                        src={`${Api.baseurl}${order.ImagePath}`}
                        alt="Order"
                        className="order-image"
                      />
                    </td>
                    {/* Download button for PDF orders */}
                  </tr>
                ))}
              </tbody>
            </table>
          ) : (
            <div className="message-container">
              <p>{message}</p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Orders;
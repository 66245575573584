import React, { useState, useEffect } from "react";
import "./Step2ReportOrder.css";
import { Api } from "../../../Api";
import axios from "axios";

const SurnameReport = ({ orderData, onSave }) => {
  const [localData, setLocalData] = useState({
    electionType: "",
    ElectionName: "",
    WardNo: "",
  });

  const [error, setError] = useState("");
  const [electionTypes, setElectionTypes] = useState([]);
  const [electionNames, setElectionNames] = useState([]);
  const [isSaving, setIsSaving] = useState(false); // Loading state

  useEffect(() => {
    const fetchElectionTypes = async () => {
      try {
        const response = await axios.post(Api.getEleType);
        if (response.data.status === 1) {
          setError("");
          setElectionTypes(response.data.data);
        } else {
          setError("Failed to fetch election types.");
        }
      } catch (error) {
        console.error("Error fetching election types:", error);
        setError("An error occurred while fetching election types.");
      }
    };

    fetchElectionTypes();
  }, []);

  const fetchElectionNames = async (selectedType) => {
    try {
      const response = await axios.post(Api.getEleName, {
        EleType: selectedType,
      });

      if (response.data.status === 1) {
        setError("");
        setElectionNames(response.data.data);
      } else {
        setError("Failed to fetch election names.");
        setElectionNames([]);
      }
    } catch (error) {
      console.error("Error fetching election names:", error);
      setError("An error occurred while fetching election names.");
      setElectionNames([]);
    }
  };

  const handleElectionTypeChange = (e) => {
    const selectedType = e.target.value;
    setLocalData({ ...localData, electionType: selectedType, ElectionName: "" });

    if (selectedType) {
      fetchElectionNames(selectedType);
    } else {
      setElectionNames([]);
    }
  };

  const handleSave = async () => {
    setError("");

    if (!localData.electionType || !localData.ElectionName || !localData.WardNo) {
      setError("All fields are required. Please fill in the missing information.");
      return;
    }

    try {
      setIsSaving(true); // Start loading

      await onSave({
        electionType: localData.electionType,
        ElectionName: localData.ElectionName,
        WardNo: localData.WardNo,
      });
    } catch (err) {
      setIsSaving(false);
      setError("An error occurred while saving the data. Please try again.");
    }
  };

  return (
    <div className="surname-report-container">
      <h3>{orderData?.product?.label || "Report"} Details</h3>

      {error && <div className="error-message">{error}</div>}

      <div className="detail-container">
        <div className="input-group-div">
          <label>
            Election Type <span className="gujarati">(ચૂંટણી પ્રકાર)</span>
          </label>
          <select className="select-component" value={localData.electionType} onChange={handleElectionTypeChange}>
            <option className="select-option" value="">Select Election Type</option>
            {electionTypes.map((type, index) => (
              <option className='select-option' key={index} value={type.eletype}>
                {type.eletype}
              </option>
            ))}
          </select>
        </div>

        <div className="input-group-div">
          <label>
            Election Name <span className="gujarati">(ચૂંટણી નામ)</span>
          </label>
          <select className="select-component"  
            value={localData.ElectionName}
            onChange={(e) => setLocalData({ ...localData, ElectionName: e.target.value })}
            disabled={!localData.electionType || electionNames.length === 0}
          >
            <option className="select-option" value="">Select Election Name</option>
            {electionNames.map((name, index) => (
              <option className="select-option" key={index} value={name.elename}>
                {name.elename}
              </option>
            ))}
          </select>
        </div>

        <div className="input-group-div">
          <label>
            Ward No <span className="gujarati">(વોર્ડ નંબર)</span>
          </label>
          <input
            type="number"
            value={localData.WardNo}
            onChange={(e) => setLocalData({ ...localData, WardNo: e.target.value })}
            placeholder="Enter Ward No"
          />
        </div>

        <div className="modal-action-2-btn">
          <button className="confirm-btn" onClick={handleSave} disabled={isSaving}>
            {isSaving ? "Saving..." : "Save"}
          </button>
        </div>
      </div>
    </div>
  );
};

export default SurnameReport;

export const serverip = "http://148.135.136.186:4898";
// export const serverip = "http://192.168.1.81:4898";

export const Api = {
    baseurl: serverip,
    login: serverip+ '/login/user',
    register: serverip+ '/add/company',
    getCompany : serverip + '/get/company',
    editCompany: serverip+ '/edit/company',
    sendOtp: serverip+ '/send/otp',
    verifyOtp: serverip+ '/verify/otp',
    addElectionSlip: serverip+ '/add/eleslip',
    addElectionReport: serverip+ '/add/electionreport',
    addTransaction: serverip+ '/add/transaction',
    getOrderList: serverip+ '/get/orderlist',
    getEleType: serverip+ '/get/eletype',
    getEleName: serverip+ '/get/elename',
    getTransaction: serverip + '/get/transactions',
    getPrat : serverip+ '/get/prat',
}
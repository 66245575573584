import React from "react";
import '../NewOrder.css';

const Step5SlipSize = ({ choices, selectedChoice, onSelect }) => {
  return (
    <>
      <h3>Select Slip Size</h3>
      <div className={`choices-container ${choices.length === 1? 'grid-1' : choices.length === 2? 'grid-2' : 'grid-2x3'}`}>
        {choices.map((choice) => (
          <div
            key={choice.id}
            className={`choice-card-slip-size ${selectedChoice && selectedChoice.id === choice.id ? 'selected' : ''}`}
            onClick={() => onSelect(choice.id)}
          >
            {choice.image && choice.image !== "" && (
              <img src={choice.image} alt={choice.label} className="choice-image" />
            )}
            <div>{choice.label}</div>
          </div>
        ))}
      </div>
    </>
  );
};

export default Step5SlipSize;

import React, { useState, useRef } from "react";
import "./ForgetPassword.css";
import PhoneInput from "react-phone-input-2";
import { Link, useNavigate } from "react-router-dom";
import "react-phone-input-2/lib/style.css";
import { FaPhoneAlt } from "react-icons/fa";
import PasswordField from "../../component/PasswordField";
import ConfirmPasswordField from "../../component/ConfirmPasswordField";
import axios from "axios";
import { Api } from "../../Api";

const ForgetPassword = () => {
  const [phone, setPhone] = useState("");
  const [otp, setOtp] = useState(["", "", "", ""]);
  const [otpError, setOtpError] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const [isConfirmPasswordVisible, setIsConfirmPasswordVisible] = useState(false);
  const [passError, setPassError] = useState("");
  const [isOtpSent, setIsOtpSent] = useState(false);
  const [isOtpVerified, setIsOtpVerified] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const otpRefs = useRef([]);
  const navigate = useNavigate();
  
  const handlePhoneChange = (value) => {
    setPhone(value);
  };
  
  const handleOtpChange = (e, index) => {
    const value = e.target.value;
    if (!isNaN(value) && value.length <= 1) {
      const newOtp = [...otp];
      newOtp[index] = value;
      setOtp(newOtp);
      
      if (value && index < otpRefs.current.length - 1) {
        otpRefs.current[index + 1].focus();
      }
    }
  };
  
  const handleKeyDown = (e, index) => {
    if (e.key === "Backspace" && !otp[index] && index > 0) {
      otpRefs.current[index - 1].focus();
    }
  };
  
  const sendOtpApi = async () => {
    try {
      setOtpError("");
      setLoading(true);
      const response = await axios.post(Api.sendOtp, {
        CompMobileNo: phone,
      });
      
      if (response.data.status === 1) {
        setIsOtpSent(true);
        // console.log("OTP sent successfully");
      } else {
        setOtpError(response.data.message);
      }
    } catch (error) {
      if (error.response) {
        if (error.response.status === 404) {
          setOtpError("Mobile Number Not Found.");
        } else if (error.response.status === 403) {
          setOtpError("Account is not active. Please contact support.");
        } else {
          setOtpError(error.response.data?.message);
        }
      } else {
        setOtpError("Unable to connect to the server. Please check your Internet connection.");
      }
    } finally {
      setLoading(false);
    }
  };
  
  const handleSendOtp = async () => {
    if (phone.length >= 10) {
      await sendOtpApi();
    } else {
      setOtpError("Please Enter a Valid Mobile Number with 10 digits.");
    }
  };
  
  const resendOtp = async () => {
    setOtp(["", "", "", ""]);
    setIsOtpVerified(false);
    await sendOtpApi();
  };
  
  const verifyOtp = async () => {
    const otpString = otp.join("");
    
    if (otpString.length === 4) {
      try {
        setOtpError("");
        setLoading(true);
        const response = await axios.post(Api.verifyOtp, {
          CompMobileNo: phone,
          CompOTP: otpString,
        });
        
        if (response.data.status === 1) {
          setIsOtpVerified(true);
          // console.log("OTP VERIFIED");
        } else {
          setIsOtpVerified(false);
          setOtpError(response.data.message);
        }
      } catch (error) {
        setIsOtpVerified(false);
        setOtpError(error.response?.data?.message || "Error verifying OTP. Please try again.");
        console.error(error);
      } finally {
        setLoading(false);
      }
    } else {
      setOtpError("Please enter a valid 4-digit OTP.");
    }
  };
  
  
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (password.length < 6) {
      setPassError("Password Must be of Minimum 6 Length");
    } else if (password !== confirmPassword) {
      setPassError("Password and Confirm Password Must be Same");
    } else {
      setPassError("");
      setLoading(true);
  
      try {
        const response = await axios.post(Api.editCompany, {
          CompMobileNo: phone,
          CompPassword: password,
        });
  
        if (response.data.status === 1) {
          setSuccessMessage("Password changed successfully!");
          navigate('/')
        } else {
          setPassError(response.data.message);
        }
      } catch (error) {
        setPassError("An error occurred while updating the password.");
        console.error(error);
      } finally {
        setLoading(false);
      }
    }
  };
  
  
  return (
    <div className="form-container">
    <div className="card input-form p-4">
    <div className="back-to-login-link">
    <Link to="/" className="back-link">
    Login
    <span className="back-icon">&rarr;</span>
    </Link>
    </div>
    <h2 className="text-center mt-3 mb-3">Forgot Your Password?</h2>
    <form onSubmit={handleSubmit}>
    <div className="form-group">
    <label htmlFor="phone" className="form-label">
    <FaPhoneAlt /> Enter your Phone Number:
    </label>
    <PhoneInput
    country={"in"}
    value={phone}
    onChange={handlePhoneChange}
    specialLabel={false}
    inputProps={{
      name: "phone",
      required: true,
      className: "input-field",
      autoFocus: true,
    }}
    countryCodeEditable={false}
    disabled={isOtpSent}
    />
    {!isOtpSent && otpError && <p className="text-danger mb-0">{otpError}</p>}
    {!isOtpSent && (
      <button
      type="button"
      className="send-otp-btn mt-3"
      onClick={handleSendOtp}
      disabled={loading}
      >
      {loading ? (
        <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
      ) : (
        "Send OTP"
      )}
      </button>
    )}
    </div>
    
    {isOtpSent && (
      <>
      {!isOtpVerified && (
        <div className="mt-3">
        <p>
        An OTP has been sent to your <b>+{phone}</b>
        </p>
        </div>
      )}
      {!isOtpVerified && (
        <div className="otp-container">
        <div className="otp-inputs">
        {Array.from({ length: 4 }).map((_, index) => (
          <input
          key={index}
          ref={(el) => (otpRefs.current[index] = el)}
          type="text"
          inputMode="numeric"
          pattern="[0-9]*"
          maxLength="1"
          value={otp[index] || ""}
          onChange={(e) => handleOtpChange(e, index)}
          onKeyDown={(e) => handleKeyDown(e, index)}
          className="otp-input"
          />
        ))}
        </div>
        </div>
      )}
      
      {!isOtpVerified && (
        <div className="verify-btn-container">
        <button type="button" onClick={verifyOtp} className="verify-btn">
        {loading ? (
          <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
        ) : (
          "Verify"
        )}
        </button>
        </div>
      )}
      {otpError && (
        <div className="verification-message">
        <p className="text-danger">{otpError}</p>
        </div>
      )}
      
      {isOtpVerified && (
        <div className="verification-message">
        <p>OTP Verified Successfully!</p>
        </div>
      )}
      
      {!isOtpVerified && (
        <div className="resend-otp">
        <button type="button" onClick={resendOtp} className="resend-btn">
        {loading ? (
          <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
        ) : (
          "Resend OTP"
        )}
        </button>
        </div>
      )}
      </>
    )}
    
    {isOtpVerified && (
      <>
      <PasswordField
      value={password}
      onChange={(e) => setPassword(e.target.value)}
      isVisible={isPasswordVisible}
      toggleVisibility={() => setIsPasswordVisible(!isPasswordVisible)}
      placeholder="Enter New Password"
      />
      <ConfirmPasswordField
      value={confirmPassword}
      onChange={(e) => setConfirmPassword(e.target.value)}
      isVisible={isConfirmPasswordVisible}
      toggleVisibility={() =>
        setIsConfirmPasswordVisible(!isConfirmPasswordVisible)
      }
      password={password}
      />
      {passError && <p className="text-danger">{passError}</p>}
      <button
      type="submit"
      className="submit-btn w-100"
      onClick={handleSubmit}
      >
      {loading ? (
        <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
      ) : (
        "Change Password"
      )}
      </button>
      </>
    )}
    </form>
    {successMessage && (
      <div className="success-message">
      <p>{successMessage}</p>
      </div>
    )}
    <div className="contact-details">
    <p>
    <b>Contact:</b> Gurukrupa Enterprise | +91 9909340404
    </p>
    </div>
    </div>
    </div>
  );
};

export default ForgetPassword;

import React from 'react';
import '../NewOrder.css'

const Step1ProductSelection = ({ choices, selectedChoice, onSelect }) => {
  return (
      <>
        <h3>Select Product</h3>
        
        <div
          className={`choices-container ${
            choices.length > 4 ? 'grid-2x3' : 'grid-2x2'
          }`}
        >
          {choices.map((choice) => (
            <div
              key={choice.id}
              className={`choice-card ${selectedChoice && selectedChoice.id === choice.id ? 'selected' : ''}`}
              onClick={() => onSelect(choice.id)}
            >
              <div>
                <div>{choice.label}</div>
                <div className="gujrati">({choice.gujaratiLabel})</div>
                {choice.status && (
                  <div className="status-message">{choice.status}</div>
                )}
              </div>
            </div>
          ))}
        </div>
      </>
  );
};

export default Step1ProductSelection;

import React from "react";
import PasswordField from "../../../component/PasswordField";
import ConfirmPasswordField from "../../../component/ConfirmPasswordField";

const Step3 = ({
  password,
  confirmPassword,
  isPasswordVisible,
  isConfirmPasswordVisible,
  setPassword,
  setConfirmPassword,
  togglePasswordVisibility,
  toggleConfirmPasswordVisibility,
  errors,
  handleNext,
  setStep,
  isLoading
}) => {
  return (
    <div>
    <PasswordField
    value={password}
    onChange={(e) => setPassword(e.target.value)}
    isVisible={isPasswordVisible}
    toggleVisibility={togglePasswordVisibility}
    placeholder="Enter New Password"
    />
    {errors.password && <p className="error">{errors.password}</p>}
    
    <ConfirmPasswordField
    value={confirmPassword}
    onChange={(e) => setConfirmPassword(e.target.value)}
    isVisible={isConfirmPasswordVisible}
    toggleVisibility={toggleConfirmPasswordVisibility}
    placeholder="Confirm Password"
    />
    {errors.confirmPassword && <p className="error">{errors.confirmPassword}</p>}
    
    <div className="navigation-buttons">
    <button type="button" onClick={() => setStep(1)} className="prev-btn">
    <span className="m-2 fs-5">&larr;</span> Previous
    </button>
    <button type="button" onClick={handleNext} className="next-btn">
    {isLoading ? (
      <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
    ) : (
      <>
      Next <span className="m-2 fs-5">&rarr;</span>
      </>
    )}
    </button>
    </div>
    </div>
  );
};

export default Step3;

import React from "react";
import { QRCodeCanvas } from "qrcode.react";

const Step7OrderSummary = ({ orderData, handleConfirm, amount, rate, upiId, credits }) => {
  const quantity = Number(orderData.Prat) || 0; // Default to Prat or 100

  // Ensure numeric values
  const safeRate = Number(rate) || 0;
  const safeAmount = Number(amount);
  const safeCredits = Number(credits) || 0;

  // Adjust credits handling
  const adjustedCredits = safeCredits < 0 ? Math.abs(safeCredits) : -safeCredits;
  const payableAmount = Math.max(safeAmount + (safeCredits < 0 ? adjustedCredits : -safeCredits), 0);

  // Format number to 2 decimal places
  const formatNumber = (num) =>
    new Intl.NumberFormat("en-IN", { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(num);

  const handlePaymentSuccess = () => {
    handleConfirm({ ...orderData, DeliveryAddress: orderData.DeliveryAddress || "" });
  };

  return (
    <div className="order-summary">
      <h3>Billing Details</h3>

      <div className="billing-container">
        {/* Left Side: Billing Details */}
        <div className="billing-details">
          <div className="billing-row">
            <span>Quantity</span>
            <span className="billing-value">₹{quantity}</span>
          </div>
          <div className="billing-row">
            <span>Rate</span>
            <span className="billing-value">x &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              {formatNumber(safeRate)}
            </span>
          </div>
          <div className="billing-row total-row">
            <span>Amount</span>
            <span className="billing-value">=&nbsp;&nbsp;₹{formatNumber(safeAmount)}</span>
          </div>

          {/* Display credits without negative sign */}
          <div className="billing-row">
            <span>Previous</span>
            <span className={`billing-value ${safeCredits < 0 ? "text-red" : "text-green"}`}>
              ₹{formatNumber(Math.abs(safeCredits))}
            </span>
          </div>

          <div className="billing-row total">
            <span>Total Payable</span>
            <span className={`billing-value ${payableAmount > 0 ? "text-red" : "text-green"}`}>
              ₹{formatNumber(payableAmount)}
            </span>
          </div>
        </div>

        {/* Right Side: QR Code Section */}
        {payableAmount > 0 && (
          <div className="qr-container">
            <h4>Scan to Pay</h4>
            <div className="qr-code-box">
              <QRCodeCanvas
                value={`upi://pay?pa=${upiId}&pn=ZadpiOrder&am=${safeAmount}&cu=INR`}
                size={180}
              />
            </div>
            <p className="qr-instructions">
              Scan the QR code with your UPI app to make the payment.
            </p>
          </div>
        )}
      </div>

      <div className="modal-action-2-btn">
        <button className="confirm-btn" onClick={handlePaymentSuccess}>
          Confirm Order
        </button>
      </div>
    </div>
  );
};

export default Step7OrderSummary;

import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { FaPlus, FaCreditCard } from "react-icons/fa";
import { QRCodeCanvas } from "qrcode.react";
import Navbar from "../../component/Navbar";
import NewOrder from "../NewOrder/NewOrder";
import axios from "axios";
import { Api } from "../../Api";
import "./Dashboard.css";

const Dashboard = () => {
  const navigate = useNavigate();
  const [transactions, setTransactions] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isPaymentDialogOpen, setIsPaymentDialogOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [compLimit, setCompLimit] = useState(null); // Store company limit here

  // ✅ Get user data from localStorage
  const userData = JSON.parse(localStorage.getItem("data"));

  // ✅ Redirect to login if userData is null
  useEffect(() => {
    if (!userData) {
      navigate("/");
    }
  }, [navigate, userData]);

  // ✅ Fetch Order Data and Transactions
  useEffect(() => {
    const fetchData = async () => {
      try {
        const transactionsResponse = await axios.post(Api.getTransaction, { CompId: userData._id });
        if (transactionsResponse.data.status === 1) {
          setTransactions(transactionsResponse.data.data);
        } else {
          setMessage(transactionsResponse.data.message || "No transactions found.");
        }

        // Fetch Company Details to get CompLimit
        const companyResponse = await axios.post(Api.getCompany, { id: userData._id });
        if (companyResponse.data.status === 1) {
          setCompLimit(companyResponse.data.data[0]?.CompLimit);
        } else {
          setMessage(companyResponse.data.message || "No company details found.");
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        setMessage(error.message);
      }
    };
    fetchData();
  }, []);

  // ✅ Calculate totalAmount (Credits) from transactions
  const totalAmount = parseFloat(
    transactions.reduce((sum, data) => sum + parseFloat(data.Amount || 0), 0)
  ).toFixed(2);

  // ✅ Handle opening modal or payment dialog
  const handleNewOrderClick = () => {
    if (compLimit && compLimit > Math.abs(totalAmount)) {
      setIsModalOpen(true);
    } else {
      setIsPaymentDialogOpen(true);
    }
  };

  // ✅ Close dialogs
  const handleCloseModal = () => setIsModalOpen(false);
  const handleClosePaymentDialog = () => setIsPaymentDialogOpen(false);

  return (
    <div className="main-container">
      <Navbar />

      <div className="content-container">
        <header className="dashboard-header">
          <h1 className="shop-name">{userData?.CompName || "Shop Name"}</h1>
          <div className="credits-container">
            <p className={`credits`}>
              <FaCreditCard />
              <span className={`amount ${totalAmount < 0 ? "red-text" : "green-text"}`}>
                ₹{Math.abs(totalAmount)}
              </span>
              <span className={`status ${totalAmount < 0 ? "red-text" : "green-text"}`}>
                ({totalAmount < 0 ? "બાકી" : "જમા"})
              </span>
            </p>
          </div>
        </header>

        <div className="new-order-container">
          <button className="new-order-btn" onClick={handleNewOrderClick}>
            <FaPlus className="icon" /> New Order
          </button>
        </div>
      </div>

      {/* ✅ Open New Order Modal if within credit limit */}
      {isModalOpen && <NewOrder handleClose={handleCloseModal} credits={totalAmount} />}

      {/* ✅ Payment Dialog with Full-Screen Overlay */}
      {isPaymentDialogOpen && (
        <>
          {/* Full-screen overlay */}
          <div className="overlay" onClick={handleClosePaymentDialog}></div>

          <div className="payment-dialog">
            <div className="payment-content">
              <h3>Insufficient Balance</h3>
              <p>Your available credits are less than the required limit.</p>
              <p>Please pay the required amount to proceed.</p>

              <div className="current-balance">
                <strong>Current Balance:</strong>
                <span className={`balance-amount ${totalAmount < 0 ? "red-text" : "green-text"}`}>
                  ₹{Math.abs(totalAmount)}
                </span>
              </div>
              {/* QR Code for Payment with only UPI ID */}
              <div className="qr-container">
                <h4>Scan to Pay</h4>
                <QRCodeCanvas value={`upi://pay?pa=${userData.UpiId}`} size={180} />
                <p className="qr-instructions">Scan this QR code with your UPI app to Pay.</p>
              </div>

              <button className="close-btn" onClick={handleClosePaymentDialog}>
                Close
              </button>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default Dashboard;

import React, { useState, useEffect } from "react";
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import Step1ProductSelection from "./NewOrderSteps/Step1ProductSelection";
import Step2ElectionSlip from "./NewOrderSteps/Step2ElectionSlip";
import Step3OrderType from "./NewOrderSteps/Step3OrderType";
import Step4CandidateList from "./NewOrderSteps/Step4CandidateList";
import Step5SlipSize from './NewOrderSteps/Step5SlipSize'
import Step6PrintDetails from "./NewOrderSteps/Step6PrintDetail";
import Step7OrderSummary from "./NewOrderSteps/Step7OrderSummary";
import axios from "axios";
import { Api } from "../../Api";
import "./NewOrder.css";
import { IoClose } from "react-icons/io5";
import ReportOrder from "./SurnameWiseReport/Step2ReportOrder";

//Importing Slip Images
import NP_A4_6 from '../../assets/NP_A4_6.jpeg';
import NP_A4_9 from '../../assets/NP_A4_9.jpeg';
import NP_A4_4 from '../../assets/NP_A4_4.jpeg';
import TP_A4_6 from '../../assets/TP_A4_6.jpeg';
import TP_A4_9 from '../../assets/TP_A4_9.jpeg';
import LS_A4_9 from '../../assets/LS_A4_9.jpeg';
import VS_A4_9 from '../../assets/VS_A4_9.jpeg';
import JP_A4_6 from '../../assets/JP_A4_6.jpeg';
import GP_Sarpanch_6 from '../../assets/GP_Sarpanch_A4_6.jpeg';
import GP_Sarpanch_9 from '../../assets/GP_Sarpanch_A4_9.jpeg';
import GP_Panel_6 from '../../assets/GP_Panel_A4_6.jpeg'
import GP_OnlyWard_6 from '../../assets/GP_OnlyWard_A4_6.jpeg'

const NewOrder = ({ handleClose, credits }) => {
  const [currentStep, setCurrentStep] = useState(1);
  const [orderData, setOrderData] = useState({
    product: null,
    electionType: null,
    orderType: null,
    candidateType: null,
    slipSize: null,
    Mudrak: null,
    Prakashak: null,
    Prat: null,
    ElectionName: null,
    WardNo: null,
    UploadedImage: null,
    DeliveryAddress: null,
    PreviewImage: null,
    //Fields for Surname AND Alphabet Report
  });
  const [orderConfirmed, setOrderConfirmed] = useState(false);
  const [isForward, setIsForward] = useState(true);
  const [apiError, setApiError] = useState('');

  const [rate, setRate] = useState(0);
  const [upiId, setUpiId] = useState("7567640404@upi"); // Default UPI ID
  const [Amount, setAmount] = useState(0);
  const [company, setCompany] = useState([]);

  useEffect(() => {
    const storedData = localStorage.getItem("data");
    if (storedData) {
      setCompany(JSON.parse(storedData));
    }
  }, []);

  const productChoice = [
    { id: 3, label: "Election Slip", gujaratiLabel: "મતદાર સ્લિપ", status: null },
    { id: 1, label: "Surname Wise Report", gujaratiLabel: "અટક મુજબ રિપોર્ટ", status: "" },
    { id: 2, label: "Alphabet Wise Report", gujaratiLabel: "અક્ષર મુજબ રિપોર્ટ", status: "" },
    { id: 4, label: "War Room App", gujaratiLabel: "યુદ્ધ રૂમ ઐપ્લકેશન", status: "Under Process" },
  ];

  const electionChoices = [
    { id: 6, label: "Nagar Palika", gujaratiLabel: "નગરપાલિકા" , value: "NP"},
    { id: 4, label: "Taluka Panchayat", gujaratiLabel: "તાલુકા પંચાયત", value: "TP" },
    { id: 3, label: "Jilla Panchayat", gujaratiLabel: "જિલ્લા પંચાયત", value: "JP" },
    { id: 1, label: "LokSabha", gujaratiLabel: "લોકસભા", value: "LS" },
    { id: 2, label: "VidhanSabha", gujaratiLabel: "વિધાનસભા",value: "VS" },
    { id: 5, label: "Gram Panchayat", gujaratiLabel: "ગ્રામ પંચાયત" , value:"GP"},
  ];

  const [orderTypeOptions, setOrderOptions] = useState([]);
  useEffect(() => {
    if (orderData.electionType?.label === 'LokSabha' || orderData.electionType?.label === 'VidhanSabha') {
      setOrderOptions([{ id: 1, label: 'MultiColor Print', gujaratiLabel: 'મલ્ટી કલર પ્રિન્ટ' }]);
    } else if (orderData.electionType?.label === 'Jilla Panchayat' || orderData.electionType?.label === 'Taluka Panchayat') {
      setOrderOptions([
        { id: 1, label: 'PDF', gujaratiLabel: 'પીડીએફ' },
        { id: 2, label: 'MultiColor Print', gujaratiLabel: 'મલ્ટી કલર પ્રિન્ટ' }
      ]);
    } else {
      setOrderOptions([
        { id: 3, label: 'PDF', gujaratiLabel: 'પીડીએફ' },
        { id: 4, label: 'MultiColor Print', gujaratiLabel: 'મલ્ટી કલર પ્રિન્ટ' },
        { id: 5, label: 'Yellow Paper Print', gujaratiLabel: 'પીળો કાગળ પ્રિન્ટ' },
        { id: 6, label: 'Pink Paper Print', gujaratiLabel: 'ગુલાબી કાગળ પ્રિન્ટ' },
        // { id: 7, label: 'Orange Paper Print', gujaratiLabel: 'નારંગી કાગળ પ્રિન્ટ' },
        // { id: 8, label: 'Green Paper Print', gujaratiLabel: 'હરિયો કાગળ પ્રિન્ટ' },
      ]);
    }
  }, [orderData.electionType]);

  const candidateChoices =
  orderData.electionType?.label === 'Nagar Palika' ? (
      [
        { id: 4, label: "1 Candidate Only", gujaratiLabel: "ફક્ત 1 ઉમેદવાર" },
        { id: 5, label: "4 Candidate Panel", gujaratiLabel: "4 ઉમેદવાર પેનલ" }
      ]
      ) : orderData.electionType?.label === 'Taluka Panchayat' ? (
        [
          { id: 4, label: "1 Candidate Only", gujaratiLabel: "ફક્ત 1 ઉમેદવાર" },
        ]
      ) : (
      [
        { id: 1, label: "Sarpanch", gujaratiLabel: "સરપંચ" },
        { id: 2, label: "Ward with Candidate", gujaratiLabel: "વોર્ડ સાથે ઉમેદવાર" },
        { id: 3, label: "Only Ward Candidate", gujaratiLabel: "ફક્ત વોર્ડ ઉમેદવાર" },
      ]
  );
  
  const [slipSizeOptions, setSlipSizeOptions] = useState([]);
  
  useEffect(() => {
    if (orderData.electionType?.value === 'JP'){
        setSlipSizeOptions([
          { id: 4, label: "A4 ma 6", image : orderData.electionType?.label === 'Jilla Panchayat' ? JP_A4_6 : ""}
        ])
    } else if (orderData.candidateType?.id === 1) {
      setSlipSizeOptions([
        // { id: 1, label: "A4 ma 2" },
        // { id: 2, label: "A4 ma 3" },
        // { id: 3, label: "A4 ma 4" },
        { id: 4, label: "A4 ma 6", image : orderData.electionType?.label === 'Taluka Panchayat'? 
          TP_A4_6: orderData.electionType?.label === 'Jilla Panchayat' ? JP_A4_6 
          : orderData.electionType?.label === 'Gram Panchayat'? GP_Sarpanch_6 : ""},
        // { id: 5, label: "A4 ma 8" },
        { id: 6, label: "A4 ma 9", image : orderData.electionType?.label === 'Taluka Panchayat'? 
          TP_A4_9: orderData.electionType?.label === 'LokSabha'?
          LS_A4_9: orderData.electionType?.label === 'VidhanSabha'? VS_A4_9
          : orderData.electionType?.label === 'Gram Panchayat'? GP_Sarpanch_9 :"" },
        // { id: 7, label: "A4 ma 12" },
      ]);
    } else if (orderData.candidateType?.id === 2) {
      setSlipSizeOptions([
        // { id: 8, label: "A4 ma 2" },
        // { id: 9, label: "A4 ma 3" },
        // { id: 10, label: "A4 ma 4" },
        { id: 11, label: "A4 ma 6", image : orderData.electionType?.label === 'Taluka Panchayat'?
          TP_A4_6: orderData.electionType?.label === 'Jilla Panchayat' ? JP_A4_6 
          : orderData.electionType?.label === 'Gram Panchayat'? GP_Panel_6 : "" },
      ]);
    } else if (orderData.candidateType?.id === 3) {
      setSlipSizeOptions([
        { id: 12, label: "A4 ma 6", image : orderData.electionType?.label === 'Taluka Panchayat'?
          TP_A4_6: orderData.electionType?.label === 'Jilla Panchayat' ? JP_A4_6 
          : orderData.electionType?.label === 'Gram Panchayat'? GP_OnlyWard_6 : "" },
        // { id: 13, label: "A4 ma 8" },
        { id: 14, label: "A4 ma 9", image : orderData.electionType?.label === 'Taluka Panchayat'? 
          TP_A4_9: orderData.electionType?.label === 'LokSabha'?
          LS_A4_9: orderData.electionType?.label === 'VidhanSabha'? VS_A4_9: "" },
        // { id: 15, label: "A4 ma 12" },
      ]);
    } else if (orderData.candidateType?.id === 4 ) {
      setSlipSizeOptions([
        { id: 16, label: "A4 ma 6", image: NP_A4_6},
        { id: 17, label: "A4 ma 9", image: NP_A4_9 },
      ])
    } else if (orderData.candidateType?.id === 5 ) {
      setSlipSizeOptions([
        { id: 18, label: "A4 ma 4", image: NP_A4_4 }
      ])
    } 
  }, [orderData.candidateType]);
  
  // useEffect(() =>{
  //   console.log(orderData);
  // },[orderData])
  
  const handleNext = async (data) => {
    
    if(data.product === 3 || orderData.product?.label === 'Election Slip'){setIsForward(true);

    // Use the latest `data` to create the payload
    const newOrderData = { ...orderData };

    if (data.product) {
        const selectedProduct = productChoice.find(choice => choice.id === data.product);
        newOrderData.product = selectedProduct ? { id: selectedProduct.id, label: selectedProduct.label } : null;
    }

    if (data.electionType) {
        const selectedElectionType = electionChoices.find(choice => choice.id === data.electionType);
        newOrderData.electionType = selectedElectionType ? { id: selectedElectionType.id, label: selectedElectionType.label, value: selectedElectionType.value } : null;
    }

    if (data.orderType) {
        const selectedOrderType = orderTypeOptions.find(choice => choice.id === data.orderType);
        newOrderData.orderType = selectedOrderType ? { id: selectedOrderType.id, label: selectedOrderType.label } : null;
    }

    if (data.candidateType) {
        const selectedCandidateType = candidateChoices.find(choice => choice.id === data.candidateType);
        newOrderData.candidateType = selectedCandidateType ? { id: selectedCandidateType.id, label: selectedCandidateType.label } : null;
    }

    if (data.slipSize) {
        const selectedSlipSize = slipSizeOptions.find(choice => choice.id === data.slipSize);
        newOrderData.slipSize = selectedSlipSize ? { id: selectedSlipSize.id, label: selectedSlipSize.label } : null;
    }
    
    // Add all other values directly from `data`
    newOrderData.ElectionName = data.ElectionName ?? newOrderData.ElectionName;
    newOrderData.WardNo = data.WardNo ?? newOrderData.WardNo;
    newOrderData.UploadedImage = data.UploadedImage ?? newOrderData.UploadedImage;
    newOrderData.Mudrak = data.Mudrak ?? newOrderData.Mudrak;
    newOrderData.Prakashak = data.Prakashak ?? newOrderData.Prakashak;
    newOrderData.Prat = data.Prat ?? newOrderData.Prat;
    
    // Set the updated orderData
    setOrderData(newOrderData);

    // Proceed based on the current step and the `data`
    if (data.product) {
        setCurrentStep(2);
    } else if (data.electionType) {
        setCurrentStep(3);
    } else if (data.orderType) {
        setCurrentStep(4);
    } else if (data.candidateType) {
        setCurrentStep(5);
    } else if (data.slipSize) {
      // Determine rate based on order type and slip size
      const selectedSlipSize = slipSizeOptions.find(choice => choice.id === data.slipSize);
      let newRate = 0;
      if (orderData.orderType?.label === "PDF") {
        newRate = company.Price_SlipPdf;  
      } else if (selectedSlipSize?.label === "A4 ma 6") {
        newRate = company.Price_Slip6;
      } else if (selectedSlipSize?.label === "A4 ma 9") {
        newRate = company.Price_Slip9;
      } else if (selectedSlipSize?.label === "A4 ma 4") {
        newRate = company.Price_Slip4;
      }
      
      // Set values
      setRate(newRate);
      setUpiId(company.UpiId || "7567640404@upi");
      setCurrentStep(6);
    } else if (data.Mudrak !== undefined || data.Prakashak !== undefined) {
        console.log('Data 2', data);
        
        const user = JSON.parse(localStorage.getItem('data'));
        const formattedAmount = Math.abs(data.Prat * rate);
        const finalAmount = Number(parseFloat(formattedAmount || 0).toFixed(2));

        console.log('Prat:', data.Prat, 'Formatted Amount:', formattedAmount, 'Final Amount:', finalAmount);

        setAmount(finalAmount);
        
        // Prepare FormData for the POST request
        const formData = new FormData();
        formData.append('userId', user._id);
        formData.append('ElectionType', newOrderData.electionType?.value || '');
        formData.append('ElectionName', newOrderData.ElectionName || '');
        formData.append('WardNo', newOrderData.WardNo);
        formData.append('SlipType', newOrderData.candidateType?.label || '');
        formData.append('SlipSize', newOrderData.slipSize?.label || '');
        formData.append('Mudrak', newOrderData.Mudrak || '');
        formData.append('Prat', newOrderData.Prat || "0");
        formData.append('Prashashak', newOrderData.Prakashak || '');
        formData.append('OrderType', newOrderData.orderType?.label || '');
        formData.append('SlipRate', parseFloat(rate));
        formData.append('Amount', parseFloat(finalAmount.toFixed(2)));

        // Append the uploaded image file
        if (newOrderData.UploadedImage) {
            formData.append('Image', newOrderData.UploadedImage); // Ensure `UploadedImage` is a `File` object
        }
        
        try {
          console.log('Calling API', Api.addElectionSlip, formData);
          
            const response = await axios.post(Api.addElectionSlip, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });
            console.log('response',response);
            
            if (response.data.status === 1) {
                setApiError('');
                const description = `${user.CompName} - ${newOrderData.electionType?.value} - ${newOrderData.ElectionName} - ${newOrderData.WardNo} - ${newOrderData.orderType?.label} - ${newOrderData.candidateType?.label} - ${newOrderData.slipSize?.label}`;
                addTransaction(response.data.data[0].OrderNo, description, finalAmount);
                setCurrentStep(7);
            } else {
              setApiError(response.data.message);
              setCurrentStep(5);
              setTimeout(() => {
                setCurrentStep(6);
              }, 500);
                console.error("Failed to add data:", response.data.message);
            }
        } catch (error) {
            console.error("Error occurred while posting data:", error);
        }
        // setCurrentStep(7);
      }}
    else if( (data.product === 1 || data.product ===2) || (orderData.product?.label === 'Surname Wise Report' || orderData.product?.label === 'Alphabet Wise Report')) {
      setIsForward(true);
      const newOrderData = { ...orderData };
      
    if (data.product) {
        const selectedProduct = productChoice.find(choice => choice.id === data.product);
        newOrderData.product = selectedProduct ? { id: selectedProduct.id, label: selectedProduct.label } : null;
    }
    if (data.electionType) {
      const selectedElectionType = electionChoices.find(choice => choice.id === data.electionType);
      newOrderData.electionType = selectedElectionType ? { id: selectedElectionType.id, label: selectedElectionType.label, value: selectedElectionType.value } : null;
    }
    newOrderData.ElectionName = data.ElectionName ?? newOrderData.ElectionName;
    newOrderData.WardNo = data.WardNo ?? newOrderData.WardNo;
    setOrderData(newOrderData);

    if(data.product){
      setCurrentStep(2);
    } else if (data.ElectionName || data.electionType || data.WardNo) {
      const user = JSON.parse(localStorage.getItem('data'));
      const userId = JSON.parse(localStorage.getItem('data'))._id;
      const payload = {
        userId: userId,
        EleType: data.electionType,
        EleName: data.ElectionName,
        WardNo: data.WardNo,
        OrderType: orderData.product?.label,
      };
    
      axios
        .post(Api.addElectionReport, payload)
        .then((response) => {
          // Check if the response status is 1
          if (response.data.status === 1) {
            // Add the transaction with Amount as 0
            const orderNo = response.data.data[0].OrderNo; // Get OrderNo from response
            const description = `${user.CompName} - ${data.electionType} - ${data.ElectionName} - ${data.WardNo}`;
            console.log('Report Response',response.data);
            
            // Add Transaction with Amount 0
            addTransaction(response.data.data[0].OrderNo, description, 0);
    
            handleConfirmOrder(); // Call handleConfirmOrder if API is successful
          }
        })
        .catch((error) => {
          console.error('API Error:', error.response?.data || error.message);
          alert('Failed to save the report. Please try again later.');
        });
    }    
    }
  };

  const addTransaction = async (orderNo, description, amount) => { 
    const user = JSON.parse(localStorage.getItem('data'));
    const currentDate = new Date().toISOString();
    
    if (user) {
      const transactionData = {
        CompId: user._id,
        CompName: user.CompName,
        Amount: amount === 0 ? 0: -amount,
        Description: description,
        OrderNo: orderNo,
        OrderDate: currentDate,
      };
      
      try {
        const response = await axios.post(Api.addTransaction, transactionData);
        
        if (response.data.status === 1) {
          setApiError('');
        } else {
          setApiError(response.data.message);
        }
      } catch (error) {
        setApiError(`Error: ${error.message}`);
      }
    }
  };  
  
  const handleBack = () => {
    setIsForward(false);
    setCurrentStep((prevStep) => prevStep - 1);
  };
  
  const handleConfirmOrder = () => {
    setOrderConfirmed(true);
    // handleClose()
    // console.log("Order Confirmed:", orderData);
  };

  useEffect(() => {
    console.log(orderData);
  }, [orderData]);

  return (
    <div className="new-order-overlay">
      {!orderConfirmed && (<div className="choice-modal">
        <div className="modal-header">
          { currentStep !== 7 && (<div className="close-icon" onClick={handleClose}>
            <IoClose size={24} color="white" />
          </div>)}
        </div>
        <TransitionGroup>
          <CSSTransition
            key={currentStep}
            timeout={300}
            classNames={isForward ? "step" : "step-reverse"}
          >
            <div className="transition-div">
              {currentStep === 1 && (
                <Step1ProductSelection
                choices={productChoice}
                selectedChoice={orderData.product}
                onSelect={(product) => handleNext({ product })}
                handleClose={handleClose}
                />
              )}
              
              {orderData.product?.label === 'Election Slip' && currentStep === 2 && (
                <Step2ElectionSlip
                choices={electionChoices}
                selectedChoice={orderData.electionType}
                onSelect={(electionType) =>handleNext({ electionType })}
                handleBack={handleBack}
                handleClose={handleClose}
                />
              )}
              
              { currentStep === 2 && (orderData.product?.label === "Surname Wise Report" || orderData.product?.label === "Alphabet Wise Report") && (
                <ReportOrder
                  orderData={orderData}
                  onSave={(updatedData) => handleNext(updatedData)}
                  onBack={handleBack}
                />
              )}

              {currentStep === 3 && (
                <Step3OrderType
                choices={orderTypeOptions}
                selectedChoice={orderData.orderType}
                onSelect={(orderType) => handleNext({ orderType })}
                handleBack={handleBack}
                handleClose={handleClose}
                />
              )}
              
              {currentStep === 4 && (
                <Step4CandidateList
                choices={candidateChoices}
                selectedChoice={orderData.candidateType}
                onSelect={(candidateType) => handleNext({ candidateType })}
                handleBack={handleBack}
                handleClose={handleClose}
                />
              )}
              
              {currentStep === 5 && (
                <Step5SlipSize
                choices={slipSizeOptions}
                selectedChoice={orderData.slipSize}
                onSelect={(slipSize) => handleNext({ slipSize })}
                handleBack={handleBack}
                handleClose={handleClose}
                />
              )}
              
              {currentStep === 6 && (
                <Step6PrintDetails
                orderData={orderData}
                onProceed={(data) => handleNext(data)}
                apierror={apiError}
                />
              )}

              {!orderConfirmed && currentStep === 7 && (
                  <Step7OrderSummary
                    orderData={orderData}
                    handleBack={handleBack}
                    handleConfirm={handleConfirmOrder}
                    handleClose={handleClose}
                    rate={rate}
                    upiId={upiId}
                    amount={Amount}
                    credits={credits}
                  />
              )}
            </div>
          </CSSTransition>
        </TransitionGroup>
        {currentStep !== 1 && currentStep !== 7 && (
          <div className="modal-actions">
            <button onClick={handleBack} className="back-btn">
              &larr;Back
            </button>
          </div>
        )}
        {/* {currentStep === 2 && orderData.product?.label === 'Surname Wise Report' && (
          <div className="modal-action-2-btn">
            <button className="confirm-btn" onClick={''}>
              Save
            </button>
          </div>
        )} */}
      </div>)}
      {orderConfirmed && (
          <div className="order-confirmation">
            <h3>Order Confirmed!</h3>
            <p>Your order has been placed successfully.</p>
            <button onClick={handleClose}>Close</button>
          </div>
        )}
    </div>
  );
};

export default NewOrder;

import React from "react";

const Step5 = ({
  frontPreview,
  backPreview,
  handleFileChange,
  errors,
  setStep,
  isLoading
}) => {
  return (
    <div>
      <div className="input-group-container">
        {/* Front of Visiting Card Upload */}
        <div className="input-group">
          <input
            type="file"
            accept="image/*"
            onChange={(e) => handleFileChange(e, "front")}
            className="input-field-iconless"
          />
          <label>Upload Front of Visiting Card</label>
          {frontPreview && (
            <div className="image-preview-r">
              <img src={frontPreview} alt="Front Preview" className="preview-img-r" />
            </div>
          )}
        </div>

        {/* Back of Visiting Card Upload */}
        <div className="input-group">
          <input
            type="file"
            accept="image/*"
            onChange={(e) => handleFileChange(e, "back")}
            className="input-field-iconless"
          />
          <label>Upload Back of Visiting Card</label>
          {backPreview && (
            <div className="image-preview-r">
              <img src={backPreview} alt="Back Preview" className="preview-img-r" />
            </div>
          )}
        </div>

        {(!frontPreview && !backPreview) && errors.filePreview && (
          <p className="error">{errors.filePreview}</p>
        )}
      </div>

      {/* Navigation Buttons */}
      <div className="navigation-buttons">
        <button type="button" onClick={() => setStep(4)} className="prev-btn">
          <span className="m-2 fs-5">&larr;</span>
          Previous
        </button>
        <button type="submit" className="submit-btn">
          {isLoading ? (
            <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
          ) : (
            <>
              Submit
            </>
          )}
        </button>
      </div>
    </div>
  );
};

export default Step5;

import React from "react";
import { FaEye, FaEyeSlash, FaLock } from "react-icons/fa";

const ConfirmPasswordField = ({ value, onChange, isVisible, toggleVisibility, password }) => {
  return (
    <div className="form-group password-group">
      <label htmlFor="confirmPassword" className="form-label">
        <FaLock /> Confirm Password:
      </label>
      <div className="input-group">
        <input
          type={isVisible ? "text" : "password"}
          id="confirmPassword"
          className="input-field-iconless"
          value={value}
          onChange={onChange}
          placeholder="Confirm password"
        />
        <span className="password-toggle" onClick={toggleVisibility}>
          {isVisible ? <FaEyeSlash /> : <FaEye />}
        </span>
      </div>
    </div>
  );
};

export default ConfirmPasswordField;

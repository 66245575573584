import React from "react";
import { MdEmail } from "react-icons/md";
import { FaMapMarkerAlt } from "react-icons/fa";

const Step4 = ({
  email,
  setEmail,
  address,
  setAddress,
  area,
  setArea,
  city,
  setCity,
  state,
  setState,
  pincode,
  setPincode,
  errors,
  handleNext,
  setStep,
  isLoading
}) => {
  return (
    <div>
    <div className="input-group">
    <MdEmail className="input-icon" />
    <input
    type="email"
    value={email}
    onChange={(e) => setEmail(e.target.value)}
    placeholder="Enter Email"
    className="input-field"
    autoComplete="email"
    />
    {errors.email && <p className="error ms-4 mt-2">{errors.email}</p>}
    </div>
    
    <div className="input-group">
    <FaMapMarkerAlt className="input-icon" />
    <input
    type="text"
    value={address}
    onChange={(e) => setAddress(e.target.value)}
    placeholder="Enter Shop Address"
    className="input-field"
    />
    {errors.address && <p className="error ms-4 mt-2">{errors.address}</p>}
    </div>
    
    <div className="input-row">
    <div className="input-group">
    <input
    type="text"
    value={area}
    onChange={(e) => setArea(e.target.value)}
    placeholder="Enter Area"
    className="input-field-iconless"
    />
    </div>
    
    <div className="input-group">
    <input
    type="text"
    value={city}
    onChange={(e) => setCity(e.target.value)}
    placeholder="Enter City"
    className="input-field-iconless"
    />
    </div>
    </div>
    <div className="input-row">
    <div className="error-group">
    {errors.area && <p className="error">{errors.area}</p>}
    </div>
    <div className="error-group">
    {errors.city && <p className="error">{errors.city}</p>}
    </div>
    </div>
    
    <div className="input-row">
    <div className="input-group">
    <input
    type="text"
    value={state}
    onChange={(e) => setState(e.target.value)}
    placeholder="Enter State"
    className="input-field-iconless"
    />
    </div>
    
    <div className="input-group">
    <input
    type="text"
    value={pincode}
    onChange={(e) => setPincode(e.target.value.replace(/\D/g, ""))}
    placeholder="Enter Pincode"
    className="input-field-iconless"
    maxLength={6}
    />
    </div>
    </div>
    <div className="input-row">
    <div className="error-group">
    {errors.state && <p className="error">{errors.state}</p>}
    </div>
    <div className="error-group">
    {errors.pincode && <p className="error">{errors.pincode}</p>}
    </div>
    </div>
    
    <div className="navigation-buttons">
    <button type="button" onClick={() => setStep(3)} className="prev-btn">
    <span className="m-2 fs-5">&larr;</span>
    Previous
    </button>
    <button type="button" onClick={handleNext} className="next-btn">
    {isLoading ? (
      <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
    ) : (
      <>
      Next <span className="m-2 fs-5">&rarr;</span>
      </>
    )}
    </button>
    </div>
    </div>
  );
};

export default Step4;

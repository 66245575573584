import React, { useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { FaHome, FaBox, FaCreditCard, FaCog, FaQuestionCircle, FaSignOutAlt, FaTag } from 'react-icons/fa';
import './Navbar.css';
import ZadpiLogo from '../assets/ZadpiLogo.png';

const Navbar = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [isLogoutModalOpen, setIsLogoutModalOpen] = useState(false);

  const handleLogout = () => {
    localStorage.removeItem('data');
    navigate('/');
  };

  return (
    <div className="navbar-container">
      <a href="/dashboard" className='logo-container'>
        <img src={ZadpiLogo} alt="Logo" className='app-logo' />
      </a>
      <ul className="navbar-items">
        {[
          { name: 'Dashboard', icon: <FaHome />, link: '/dashboard' },
          { name: 'Orders', icon: <FaBox />, link: '/orders' },
          { name: 'Transactions', icon: <FaCreditCard />, link: '/transaction' },
          { name: 'Price', icon: <FaTag />, link: '/prices' },
          { name: 'Settings', icon: <FaCog />, link: '#' },
          { name: 'Help', icon: <FaQuestionCircle />, link: '#' },
        ].map((item, index) => (
          <li key={index} className={`navbar-item ${location.pathname === item.link ? 'active' : ''}`}>
            <Link to={item.link} className="navbar-link">
              <div className="icon">{item.icon}</div>
              <span className="field-name">{item.name}</span>
            </Link>
          </li>
        ))}
        {/* Logout Button */}
        <li className="navbar-item">
          <button className="navbar-link logout-btn" onClick={() => setIsLogoutModalOpen(true)}>
            <div className="icon"><FaSignOutAlt /></div>
            <span className="field-name">Logout</span>
          </button>
        </li>
      </ul>

      {/* Logout Confirmation Modal */}
      {isLogoutModalOpen && (
        <div className="logout-modal">
          <div className="logout-modal-content">
            <h3>Confirm Logout</h3>
            <p>Are you sure you want to logout?</p>
            <div className="logout-modal-actions">
              <button className="logout-confirm-btn" onClick={handleLogout}>Yes, Logout</button>
              <button className="logout-cancel-btn" onClick={() => setIsLogoutModalOpen(false)}>Cancel</button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Navbar;

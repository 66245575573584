import React, { forwardRef } from 'react';

const Step2 = forwardRef((props, ref) => {
  const {
    otpSent,
    phone,
    otp,
    errors,
    otpVerified,
    resendOtp,
    handleOtpChange,
    handleKeyDown,
    setStep,
    onNext,
    isLoading,
  } = props;
  
  return (
    <div>
    {otpSent && (
      <div className="otp-sent-message">
        <p>
          ✅ Check your <b style={{color:"green"}}>WhatsApp</b> for the OTP message
        </p>
        <p>
          An OTP has been sent to <b>+{phone}</b>
        </p>
      </div>
    )}
    
    <div className="otp-container">
    <div className="otp-inputs">
    {Array.from({ length: 4 }).map((_, index) => (
      <input
      key={index}
      ref={(el) => (ref.current[index] = el)}
      type="text"
      inputMode="numeric"
      pattern="[0-9]*"
      maxLength="1"
      value={otp[index] || ""}
      onChange={(e) => handleOtpChange(e, index)}
      onKeyDown={(e) => handleKeyDown(e, index)}
      className="otp-input"
      />
    ))}
    </div>
    </div>
    
    {errors.otp && <p className="error">{errors.otp}</p>}
    
    {otpVerified !== null && (
      <div className="verification-message">
      <p>{otpVerified ? "OTP Verified!" : ""}</p>
      </div>
    )}
    
    <div className="resend-otp">
    <button type="button" onClick={resendOtp} className="resend-btn">
    Resend OTP
    </button>
    </div>
    
    <div className="navigation-buttons">
    <button type="button" onClick={() => setStep(1)} className="prev-btn">
    <span className="m-2 fs-5">&larr;</span> Previous
    </button>
    <button type="button" onClick={onNext} className="next-btn" disabled={isLoading}>
    {isLoading ? (
      <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
    ) : (
      <>
      Verify <span className="m-2 fs-5">&rarr;</span>
      </>
    )}
    </button>
    </div>
    </div>
  );
});

export default Step2;

import React from "react";
import '../NewOrder.css'
const Step4CandidateList = ({ choices, selectedChoice, onSelect }) => {
  return (
    <>
      <h3>Select Candidate Type</h3>
      <div className={`choices-container ${ choices.length === 2 ? 'grid-2' : 'grid-2x3'}`}>
        {choices.map((choice) => (
          <div
            key={choice.id}
            className={`choice-card ${selectedChoice && selectedChoice.id === choice.id ? 'selected' : ''}`}
            onClick={() => onSelect(choice.id)}
          >
            <div>
              <div>{choice.label}</div>
              <div className="gujrati">({choice.gujaratiLabel})</div>
            </div>
          </div>
        ))}
      </div>
    </>
  );
};

export default Step4CandidateList;

import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Api } from '../../../Api';

const Step6PrintDetails = ({ orderData, onProceed, apierror }) => {
  const { UploadedImage, Prakashak, Mudrak, Prat, PreviewImage } = orderData;

  const [localData, setLocalData] = useState({
    electionName: '',
    wardNo: orderData.WardNo || '',
    mudrak: Mudrak || '',
    prashashak: Prakashak || '',
    prat: Prat || '',
    uploadedImage: UploadedImage || null,
    previewImage: PreviewImage || null,
  });

  const [electionNames, setElectionNames] = useState([]);
  const [error, setError] = useState(apierror || '');
  const [isLoading, setIsLoading] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const isNP = orderData.electionType?.value === 'NP';

  // Fetch Election Names when electionType changes
  useEffect(() => {
    const fetchElectionNames = async () => {
      if (!orderData.electionType?.value) return;

      setIsLoading(true);
      try {
        const response = await axios.post(Api.getEleName, {
          EleType: orderData.electionType.value,
        });

        if (response.data.status === 1) {
          setElectionNames(response.data.data);
        } else {
          setElectionNames([]);
          setError('Failed to fetch election names.');
        }
      } catch (error) {
        console.error('Error fetching election names:', error);
        setElectionNames([]);
        setError('An error occurred while fetching election names.');
      } finally {
        setIsLoading(false);
      }
    };

    fetchElectionNames();
  }, [orderData.electionType?.value]);

  // Fetch Prat value when Election Name or Ward No changes
  useEffect(() => {
    const fetchPrat = async () => {
      if (!localData.electionName || (isNP && !localData.wardNo)) return;

      const cleanElectionName = localData.electionName.replace(/\s*W-\d+/i, '').trim();

      try {
        const response = await axios.post(Api.getPrat, {
          EleType: orderData.electionType?.value,
          EleName: cleanElectionName,
          ...(isNP && { WardNo: parseInt(localData.wardNo) })
        });

        if (response.data.status === 1) {
          setError('');
          setLocalData((prevData) => ({
            ...prevData,
            prat: response.data.data.Qty_Prat || '',
          }));
        } else {
          setLocalData((prevData) => ({ ...prevData, prat: 0 }));
          setError(response.data.message || 'Failed to fetch Prat.');
        }
      } catch (error) {
        console.error('Error fetching Prat:', error);
        setLocalData((prevData) => ({ ...prevData, prat: 0 }));
        setError(error.message);
      }
    };

    fetchPrat();
  }, [localData.electionName, localData.wardNo]);

  const handleNext = () => {
    if (isSubmitting) return;

    const { electionName, wardNo, mudrak, prashashak, prat, uploadedImage, previewImage } = localData;

    // Check if all fields are non-empty and valid
    if (!electionName.trim() || !mudrak.trim() || !prashashak.trim() || isNaN(prat) || prat === '') {
      setError('All fields are required to proceed.');
      return;
    }

    if (!uploadedImage) {
      setError('Please upload an Image.');
      return;
    }

    setError('');
    setIsSubmitting(true);
    console.log({
      ElectionName: electionName,
      WardNo: isNP ? wardNo : 0,
      Mudrak: mudrak,
      Prakashak: prashashak,
      Prat: Number(prat),
      UploadedImage: uploadedImage,
      PreviewImage: previewImage,
    });
    
    onProceed({
      ElectionName: electionName,
      WardNo: isNP ? wardNo : 0,
      Mudrak: mudrak,
      Prakashak: prashashak,
      Prat: Number(prat),
      UploadedImage: uploadedImage,
      PreviewImage: previewImage,
    });

    setTimeout(() => setIsSubmitting(false), 3000);
  };

  const handleImageUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        setLocalData((prevData) => ({
          ...prevData,
          uploadedImage: file,
          previewImage: reader.result,
        }));
      };
      reader.readAsDataURL(file);
    }
  };

  return (
    <>
      <h3>Slip Details</h3>
      <div className="detail-container">
        {/* Election Name & Ward No (Same Line) */}
        <div className="inp-group inline election-name-input">
          <div>
            <label>
              Election Name <span className="gujarati">(ચૂંટણી નામ)</span>
            </label>
            <select
              value={localData.electionName}
              onChange={(e) => setLocalData({ ...localData, electionName: e.target.value })}
              disabled={isLoading || electionNames.length === 0}
            >
              <option value="">Select Election</option>
              {electionNames.map((item, index) => (
                <option key={index} value={item.elename}>
                  {item.elename}
                </option>
              ))}
            </select>
            {isLoading && <span className="loading-text">Loading...</span>}
          </div>

          {/* Ward No (Only for NP, but keeping space for TP to align properly) */}
          <div>
            {isNP ? (
              <>
                <label>
                  Ward No <span className="gujarati">(વોર્ડ નંબર)</span>
                </label>
                <input
                  type="number"
                  value={localData.wardNo}
                  onChange={(e) => {
                    const wardNoValue = e.target.value;

                    // Allow only positive numbers or empty string
                    if (wardNoValue === '' || parseInt(wardNoValue) > 0) {
                      setLocalData((prevData) => ({
                        ...prevData,
                        wardNo: wardNoValue,
                        prat: wardNoValue ? prevData.prat : '', // Clear prat if wardNo is empty
                      }));
                    }
                  }}
                  placeholder="Enter Ward No"
                />
              </>
            ) : (
              <div style={{ visibility: 'hidden', height: '1px' }} /> // Keeps layout aligned
            )}
          </div>
        </div>

        <div className="inp-group image-upload-container">
          <div className="upload-field">
            <label>
              Upload Image <span className="gujarati">(ફોટો અપલોડ કરો)</span>
            </label>
            <input type="file" accept="image/*" onChange={handleImageUpload} />
          </div>
          {localData.previewImage && (
            <div className="image-preview">
              <img src={localData.previewImage} alt="Preview" />
            </div>
          )}
        </div>

        {/* Mudrak, Prakashak, and Prat */}
        <div className="inp-group inline">
          <div>
            <label>
              Mudrak <span className="gujarati">(મુદ્રક)</span>
            </label>
            <input
              type="text"
              value={localData.mudrak}
              onChange={(e) => setLocalData({ ...localData, mudrak: e.target.value })}
              placeholder="Enter Mudrak"
            />
          </div>
          <div>
            <label>
              Prakashak <span className="gujarati">(પ્રકાશક)</span>
            </label>
            <input
              type="text"
              value={localData.prashashak}
              onChange={(e) => setLocalData({ ...localData, prashashak: e.target.value })}
              placeholder="Enter Prakashak"
            />
          </div>
          <div>
            <label>
              Prat <span className="gujarati">(પ્રત)</span>
            </label>
            <input type="number" value={localData.prat} disabled placeholder="Prat" />
          </div>
        </div>

        {/* Error Message */}
        {error && <div className="error-mess">{error}</div>}

        {/* Next Button */}
        <button className="print-btn" onClick={handleNext} disabled={isSubmitting}>
          {isSubmitting ? 'Processing...' : 'Next'}
        </button>
      </div>
    </>
  );
};

export default Step6PrintDetails;

import React from "react";
import "../NewOrder.css";

const Step2ElectionSlip = ({ choices, selectedChoice, onSelect }) => {

  // Elections that should be disabled
  const disabledElections = ["LS", "VS", "GP"];

  const handleChoiceSelection = (choice) => {
    if (!disabledElections.includes(choice.value)) {
      onSelect(choice.id);
    }
  };

  return (
    <>
      <h3>Select Election Slip Type</h3>
      <div
        className={`choices-container ${
          choices.length > 4 ? "grid-2x3" : "grid-2x2"
        }`}
      >
        {choices.map((choice) => {
          const isDisabled = disabledElections.includes(choice.value);
          
          return (
            <div
              key={choice.id}
              className={`choice-card ${selectedChoice && selectedChoice.id === choice.id ? 'selected' : ''} ${isDisabled ? 'disabled' : ''}`}
              onClick={() => handleChoiceSelection(choice)}
              style={{ cursor: isDisabled ? "not-allowed" : "pointer", opacity: isDisabled ? 0.5 : 1 }}
            >
              <div>
                <div>{choice.label}</div>
                <div className="gujrati">({choice.gujaratiLabel})</div>
              </div>
            </div>
          );
        })}
      </div>
    </>
  );
};

export default Step2ElectionSlip;
